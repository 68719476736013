<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h3><i class="pi pi-file" style="font-size: 1.6rem"></i> Análise de Seguro Fiança</h3>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        
        <BlockUI :blocked="loading>0">
          <Steps v-model:model="items" :readonly="false">
            <template #item="{item}">
                <router-link :to="item.to" custom v-slot="{href, navigate, isExactActive}">
                  <li :class="{'p-steps-item': true, 'p-highlight p-steps-current': isExactActive}" role="tab" :aria-selected="isExactActive" :aria-expanded="isExactActive">
                    <a :href="href" @click="navigate" class="p-menuitem-link" role="presentation">
                      <span class="p-steps-number">{{item.step}}</span>
                      <span class="p-steps-title" :style="(item.error)?'color: red':''">{{item.label}} <span class="p-steps-icon" v-if="item.error"><i class="pi pi-times"></i></span></span>
                    </a>
                  </li>
                </router-link>
            </template>
          </Steps>
        </BlockUI>

        <div class="step-content">
          <router-view
            v-slot="{ Component }"
            v-model:imobiliaria="imobiliaria"
            v-model:processo="processo"
            v-model:errorData="errorObject"
            @prev-page="prevPage($event)"
            @next-page="nextPage($event)"
            @complete="complete"
          >
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>

      </div>
    </div>
  </div>

  <Dialog header="Imobiliária" :closable="false" :modal="true" v-model:visible="dialogImobiliaria">
    <div class="p-field">
      <label for="imobiliaria" class="p-d-block">Selecione a Imobiliária responsável por esse cadastro:</label>
      <Dropdown
        id="imobiliaria"
        v-model="imobiliaria"
        :options="imobiliarias"
        :showClear="true"
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione"
        :filter="true"
        filterPlaceholder="Buscar Imobiliária"
        style="width: 300px"
      />
    </div>

  </Dialog>


  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>
export default {
  data() {
    return {
      loading: 0,
      analiseAutomatica: false,
      processo: null,
      items: [
        {
          step: "1",
          label: "Locação",
          to: "/processo",
          error: false
        },
        {
          step: "2",
          label: "Pretendentes",
          to: "/processo/pretendentes",
          error: false
        },
        {
          step: "3",
          label: "Coberturas",
          to: "/processo/coberturas",
          error: false
        },
        {
          step: "4",
          label: "Confirmação",
          to: "/processo/confirmacao",
          error: false
        },
      ],
      formObject: {},
      errorObject: [],
      errorTabsObject: [],

      dialogImobiliaria: false,

      imobiliarias: [],
      imobiliaria: null,

    };
  },
  mounted() {
    const self = this;

    this.loading++;
    this.$api
      .get("/seguradoras/?fields=id&filters={%22integrada%22:[1]}")
      .then(function (response) {
        const dados = response.data;
        if (dados.success) { 
          self.analiseAutomatica = dados.data.length > 0;
        } else {
          self.analiseAutomatica = false;
        }

        self.loading--;

      });

    if(this.$auth.currentUser.imobiliaria == null) {

      this.loading++;

      this.$api
        .get("/imobiliarias/?fields=nome")
        .then(function (response) {
          const dados = response.data;
          if (dados.success) { 
            self.imobiliarias = dados.data;
          }

          if(typeof self.$route.params.id == "string" && self.$route.params.id != "") {

            let timerProcesso = setInterval(() => {
              if(self.processo != null) {

                clearInterval(timerProcesso);

                self.imobiliaria = self.processo.imobiliaria;

                self.loading--;

              }
            }, 500);

          } else {

            self.dialogImobiliaria = true;
            self.loading--;

          }

        });
    } else {
      this.imobiliaria = this.$auth.currentUser.imobiliaria;
    }

    if(typeof this.$route.params.id == "string" && this.$route.params.id != "") {
      
      this.loading++;

      this.$api
        .get(
          "/processos/" + this.$route.params.id
        )
        .then(function (response) {

          const dados = response.data;

          if(!dados.success) {
            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });
          } else {
            self.processo = dados.data;
          }

          self.loading--;

        }).catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

            self.loading--;

          }

        });

    }

  },
  watch: {
    imobiliaria(val) {
      if(typeof val == 'number') {
        this.dialogImobiliaria = false;
      }
    }
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }

      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    complete() {

      const self = this;
      const criar = true;

      this.errorObject = [];

      let dados = {
        
        'locacao_motivo': this.$utils.valOrNull(this.formObject.locacao_motivo),
        'locacao_empresa_ramo_atividade': this.$utils.valOrNull(this.formObject.locacao_empresa_ramo_atividade),
        'locacao_empresa_cnpj': this.$utils.valOrNull((typeof this.formObject.locacao_empresa_cnpj != "undefined")?this.formObject.locacao_empresa_cnpj.replace(/[^0-9]/g, ""):""),

        'imovel' : this.$utils.valOrNull(this.formObject.imovel),

        'locacao_finalidade' : this.$utils.valOrNull(this.formObject.locacao_finalidade),
                
        'valor_aluguel' : this.$utils.valOrNull(this.formObject.valor_aluguel),
        'valor_luz' : this.$utils.valOrNull(this.formObject.valor_luz),
        'valor_agua' : this.$utils.valOrNull(this.formObject.valor_agua),
        'valor_gas' : this.$utils.valOrNull(this.formObject.valor_gas),
        'valor_condominio' : this.$utils.valOrNull(this.formObject.valor_condominio),
        'valor_iptu' : this.$utils.valOrNull(this.formObject.valor_iptu),

        'cobertura_multas_contratuais' : this.$utils.valOrNull(this.formObject.cobertura_multas_contratuais),
        'cobertura_pintura_externa' : this.$utils.valOrNull(this.formObject.cobertura_pintura_externa),
        'cobertura_pintura_interna' : this.$utils.valOrNull(this.formObject.cobertura_pintura_interna),
        'cobertura_danos_imovel' : this.$utils.valOrNull(this.formObject.cobertura_danos_imovel),

        'meses' : this.$utils.valOrNull(this.formObject.meses),

        'aceito' : 1,

        'pretendentes': this.$utils.valOrNull(this.formObject.pretendentes),

        'novo': this.formObject.novo
      
      };

      if(typeof this.imobiliaria == 'number') {
        dados['imobiliaria'] = this.imobiliaria;
      }

      if (!criar) {
        dados["_method"] = "PUT";
      }

      this.loading++;

      this.$api
        .post(
          "/processos" + (!criar ? "/" + this.$route.params.id : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.loading--;

          if(criar) {
            if(self.analiseAutomatica)
              self.$router.push({ path: `/processo/analise/${response.data.data.id}` });
            else
              self.$router.push({ path: `/processos` });
          } else
            self.$router.push({ path: `/processos` });

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const idxFields = {
                imovel: 0,
                locacao_finalidade: 0,
                locacao_motivo: 0,
                locacao_empresa_ramo_atividade: 0,
                locacao_empresa_cnpj: 0,
                pretendentes:1,
                valor_aluguel: 2,
                valor_luz: 2,
                valor_agua: 2,
                valor_gas: 2,
                valor_condominio: 2,
                valor_iptu: 2,
                valor_multas_contratuais: 2,
                valor_pintura_externa: 2,
                valor_pintura_interna: 2,
                valor_danos_imovel: 2,
                meses: 2,
                novo: 2,
                aceito: 3
              };

              for(const f of response.data.fields) {

                if(typeof idxFields[f] != "undefined") {
                  if(self.errorTabsObject.indexOf(idxFields[f]) === -1)
                    self.errorTabsObject.push(idxFields[f]);
                } else {
                  if(self.errorTabsObject.indexOf(0) === -1)
                    self.errorTabsObject.push(0);
                }

                self.errorObject.push(f);

              }

              for(const t of self.errorTabsObject) {
                self.items[t].error = true;
              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

            setTimeout(() => {
              for(const t in self.items) {
                self.items[t].error = false;
              }
            }, 5000);

            self.loading--;

          }

        });

    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

::v-deep(.shadow-none) {
  box-shadow: none;
  -webkit-box-shadow: none;
}

::v-deep(.p-steps-icon) {
    vertical-align: middle;
}
</style>
